import React, { useEffect, useRef } from "react";
import SocialMediaLinks from "../Utility/SocialMediaLinks"
import { motion, useCycle } from "framer-motion";
import { Link, NavLink } from 'react-router-dom';
import { replaceURL } from '../Utility/replaceURL';
import { useLogin } from '../Auth/LoginContext'

import {ReactComponent as AccountIcon} from '../../assets/carbon_user.svg';
import "./MobileNavigation.scss"

// Naive implementation - in reality would want to attach
// a window or resize listener. Also use state/layoutEffect instead of ref/effect
// if this is important to know on initial client render.
// It would be safer to  return null for unmeasured states.
export const useDimensions = ref => {
	const dimensions = useRef({ width: 0, height: 0 });
	useEffect(() => {
		dimensions.current.width = ref.current.offsetWidth;
		dimensions.current.height = ref.current.offsetHeight;
	});
	return dimensions.current;
};

const RenderLink = props => {
	let activeClassName = (props.item.search) ? "" : "active"
	if( replaceURL(props.item.url) === '/' ) {
		return <NavLink exact 
			activeClassName={activeClassName}
			to={{ 
				pathname: replaceURL(props.item.url).replace("?"+props.item.search) , 
				state: { pageID: props.item.id },
				search: props.item.search,
			}}
			onClick={function(ev) { 
				props.toggle();
			}}
			>{props.item.title}</NavLink>;
	}
	else {
		return <NavLink 
			activeClassName={activeClassName}
			to={{ 
				pathname: replaceURL(props.item.url), 
				state: { pageID: props.item.id }, 
				search: props.item.search,
			}}
			onClick={function(ev) { 
				const childElement = ev.target;
				const parentElement = childElement.closest(".has_children");
				if(parentElement) {
					parentElement.style.overflow = 'hidden';
					setTimeout(function() { parentElement.style.overflow = 'initial'; }, 200)
				}
				props.toggle();
			}}
			>{props.item.title}</NavLink>;
	}
}

const menu_item_variants = {
	open: {
		y: 0,
		opacity: 1,
		transition: {
	  		y: { stiffness: 1000, velocity: -100 }
		}
	},
	closed: {
		y: 50,
		opacity: 0,
		transition: {
	  		y: { stiffness: 1000 }
		}
	}
};

export const MenuItem = ({ item, i, toggle }) => {
 	return (
	<motion.li className="dynamic" variants={menu_item_variants} whileHover={{ scale: 1 }} whileTap={{ scale: 1 }}>
		<RenderLink item={item} toggle={toggle} />
	</motion.li>
	);
};

const navigation_variants = {
	open: { transition: { staggerChildren: 0.07, delayChildren: 0.2 } },
	closed: { transition: { staggerChildren: 0.05, staggerDirection: -1 } }
};

export const Navigation = ({items, toggle, setLogin}) => {
	const { login, logout, user } = useLogin();
	return (
		<motion.ul variants={navigation_variants}>
			{items.map((item, i) => (
	  			<MenuItem item={item} toggle={toggle} i={i} key={i} />
			))}
			<li className="spacer"></li>
			{(user && user.loggedin) && <motion.li className="static mobile-navigation-my-account" variants={menu_item_variants}>
				<NavLink className="" to="/my-account" onClick={function(ev) { toggle(); }}><span className="my-account-icon"><AccountIcon /></span> My account</NavLink>
			</motion.li>}
			{(user && user.loggedin) && <motion.li className="static mobile-navigation-logout" variants={menu_item_variants}>
				<Link to="/login" onClick={function(ev) { toggle();logout(); }}>Logout</Link>
			</motion.li>}
			
			{!login && <motion.li className="static mobile-navigation-signin" variants={menu_item_variants}>
				<NavLink className="" to="/login" onClick={function(ev) { toggle(); }}>Sign In</NavLink>
			</motion.li>}
			{!login && <motion.li className="static mobile-navigation-signup" variants={menu_item_variants}>
				<NavLink className="" to="/signup" onClick={function(ev) { toggle(); }}>Sign Up</NavLink>
			</motion.li>}

			<motion.li className="static mobile-navigation-social-media" variants={menu_item_variants}>
				<SocialMediaLinks />
			</motion.li>
			<motion.li className="static mobile-copyright-privacy" variants={menu_item_variants}>
				<span>&copy;2023 Relationflix</span> <Link to="/privacy-policy">Privacy Policy</Link>
			</motion.li>
		</motion.ul>
	)
};

const Path = props => (
	<motion.path
		fill="transparent"
		strokeWidth="3"
		stroke="hsl(0, 0%, 18%)"
		strokeLinecap="round"
		{...props}
	/>
);

export const MenuToggle = ({ toggle }) => {
	return (
	<button onClick={toggle}>
		<svg width="23" height="23" viewBox="0 0 23 23">
			<Path
				variants={{
					closed: { d: "M 2 2.5 L 20 2.5" },
					open: { d: "M 3 16.5 L 17 2.5" }
				}}
			/>
			<Path
				d="M 2 9.423 L 20 9.423"
				variants={{
					closed: { opacity: 1 },
					open: { opacity: 0 }
				}}
				transition={{ duration: 0.1 }}
			/>
			<Path
				variants={{
					closed: { d: "M 2 16.346 L 20 16.346" },
					open: { d: "M 3 2.5 L 17 16.346" }
				}}
			/>
		</svg>
	</button>
	)
};



const sidebar = {
	open: (height = 1000) => ({
		height: `${height}px`,
		background: "rgba(0,0,0,1)",
		transition: {
			type: "spring",
			stiffness: 20,
			restDelta: 2
		}
	}),
	closed: {
		height: "0px",
		background: "rgba(0,0,0,0.5)",
		transition: {
			delay: 0.2,
			type: "spring",
			stiffness: 400,
			damping: 40
		}
	}
};

export const MobileNavigation = ({items,setLogin}) => {
	const [isOpen, toggleOpen] = useCycle(false, true);
	const containerRef = useRef(null);
	const { height } = useDimensions(containerRef);
	if(isOpen) {
		document.documentElement.classList.add('mobile-nav-open');
	} else {
		document.documentElement.classList.remove('mobile-nav-open');
	}

	return (
		<motion.nav
			initial={false}
			animate={isOpen ? "open" : "closed"}
			custom={height}
			ref={containerRef}
			className={"mobile-navigation "+( isOpen ? "open" : "closed" )}
		>
			<MenuToggle toggle={() => toggleOpen()} />
			<div className="mobile-navigation-background-wrapper"><motion.div className="background" variants={sidebar} /></div>
			<Navigation items={items} toggle={toggleOpen} setLogin={setLogin} />
		</motion.nav>
	);
};

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		document.getElementById('root').scrollTo(0, 0);
		//setTimeout(function() { document.getElementById('root').scrollTo(0, 0); }, 160);
	}, [pathname]);

return null;
}
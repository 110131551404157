import React, { useState, useEffect, createContext, useContext } from 'react';
import WPApiFetch from '../Utility/WPApi'

export const ThemeOptionsContext = createContext();

export default function ThemeOptionsProvider({children}) {
	const [options, setOptions] = useState(null);
	useEffect(() => {
	    async function fetchOptions() {
			const data =  await WPApiFetch('/acf/v3/options/options');
			setOptions( data );
	    }
	    fetchOptions();
	}, [])

	useEffect(() => {
	    function hexToRgb(hex) {
	    	const value = hex.replace("#","");
		    var bigint = parseInt(value, 16);
		    var r = (bigint >> 16) & 255;
		    var g = (bigint >> 8) & 255;
		    var b = bigint & 255;
		    return r + "," + g + "," + b;
		}
	    function setStyles() {
		    const style = document.createElement('style');
		   	const head = document.querySelector('head');
		    style.setAttribute('id', 'rflix-styles');
		    style.innerHTML = `
		    	:root {
		    		--rflix-foreground: rgb(${hexToRgb(options.acf.colors.foreground)});
		    		--rflix-background: rgb(${hexToRgb(options.acf.colors.background)});
		    		--rflix-primary: rgb(${hexToRgb(options.acf.colors.primary)});
		    		--rflix-secondary: rgb(${hexToRgb(options.acf.colors.secondary)});
		    		--rflix-tertiary: rgb(${hexToRgb(options.acf.colors.tertiary)});
		    		--rflix-gray: rgb(${hexToRgb(options.acf.colors.gray)});

					--rflix-foreground-darker:  color-mix(in srgb,var(--rflix-foreground),#000 15%);
					--rflix-foreground-darkest: color-mix(in srgb,var(--rflix-foreground),#000 30%);
					--rflix-background-darker:  color-mix(in srgb,var(--rflix-background),#000 15%);
					--rflix-background-darkest: color-mix(in srgb,var(--rflix-background),#000 30%);
					--rflix-primary-darker:  color-mix(in srgb,var(--rflix-primary),#000 15%);
					--rflix-primary-darkest: color-mix(in srgb,var(--rflix-primary),#000 30%);
					--rflix-secondary-darker:  color-mix(in srgb,var(--rflix-secondary),#000 15%);
					--rflix-secondary-darkest: color-mix(in srgb,var(--rflix-secondary),#000 30%);
					--rflix-tertiary-darker:  color-mix(in srgb,var(--rflix-tertiary),#000 15%);
					--rflix-tertiary-darkest: color-mix(in srgb,var(--rflix-tertiary),#000 30%);
					
					--rflix-foreground-lighter:  color-mix(in srgb,var(--rflix-foreground),#FFF 15%);
					--rflix-foreground-lightest: color-mix(in srgb,var(--rflix-foreground),#FFF 30%);
					--rflix-background-lighter:  color-mix(in srgb,var(--rflix-background),#FFF 15%);
					--rflix-background-lightest: color-mix(in srgb,var(--rflix-background),#FFF 30%);					
					--rflix-primary-lighter:  color-mix(in srgb,var(--rflix-primary),#FFF 15%);
					--rflix-primary-lightest: color-mix(in srgb,var(--rflix-primary),#FFF 30%);
					--rflix-secondary-lighter:  color-mix(in srgb,var(--rflix-secondary),#FFF 15%);
					--rflix-secondary-lightest: color-mix(in srgb,var(--rflix-secondary),#FFF 30%);
					--rflix-tertiary-lighter:  color-mix(in srgb,var(--rflix-tertiary),#FFF 15%);
					--rflix-tertiary-lightest: color-mix(in srgb,var(--rflix-tertiary),#FFF 30%);

		    		--rflix-foreground-rgb: ${hexToRgb(options.acf.colors.foreground)};
		    		--rflix-background-rgb: ${hexToRgb(options.acf.colors.background)};
		    		--rflix-primary-rgb: ${hexToRgb(options.acf.colors.primary)};
		    		--rflix-secondary-rgb: ${hexToRgb(options.acf.colors.secondary)};
		    		--rflix-tertiary-rgb: ${hexToRgb(options.acf.colors.tertiary)};
		    		--rflix-gray-rgb: ${hexToRgb(options.acf.colors.gray)};
		    	}
		    `;
			
		    head.insertBefore(style, head.firstChild);
	    }
	    options && options.acf && setStyles();
	  }, [options]);

	return <ThemeOptionsContext.Provider value={options}>{children}</ThemeOptionsContext.Provider>
}

export function useThemeOptions() {
	const context = useContext(ThemeOptionsContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}
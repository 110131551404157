import { getBaseURL } from "./getBaseURL";

export function unSlash(path) {
	path = path.replace(getBaseURL(), '');
	path = path.replace("http://", "http---")
	path = path.replace("https://", "https---")
	path = path.replace("//", "/")
	path = path.replace("http---", "http://")
	path = path.replace("https---", "https://")
	if (path.substr(-1) === '/' && path.length > 1) {
		path = path.substr(0, path.length - 1);
	}
	return path;
}
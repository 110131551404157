import React, { useState, useEffect } from "react"
import Parser from 'html-react-parser';
import Skeleton from "react-loading-skeleton";
import WPApiFetch from "../Utility/WPApi";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { getBaseURL } from "../Utility/getBaseURL"
import { useLogin } from '../Auth/LoginContext'

export default function AccountNavigation(props) {
	const [navigation,setNavigation] = useState(false);
	const [loading,setLoading] = useState(false);
	const location = useLocation();
	const params = useParams();
	const { login, logout, user } = useLogin();
	const { isNavigation } = props; 
   	async function fetchNavigation() {
		setLoading(true);
		const data = await WPApiFetch(global.config.restBase+'wc_account_navigation'+location.pathname.replace('/my-account','')+(isNavigation ? '?nav=true' : ''), null, "GET", null, "include", user.nonce)
		setNavigation(data[0]);
		setLoading(false);
    }
	
	useEffect(() => {
		if(!loading && user && user.loggedin && user.nonce) { fetchNavigation(); }
	}, [params.wc_object, user])
	/*
	useEffect(() => {
	    console.log(user);
	    console.log(login);
	    if(!loading && user && user.loggedin) { fetchNavigation(); }
	}, [])	
	*/
	return (
		<div className="woocommerce-MyAccount-navigation">
			{!loading && navigation && Parser(navigation, {
				replace: (domNode) => {
					if (domNode.name === 'a') {
						var path = domNode.attribs.href.replace(getBaseURL(), '').replace(global.config.buildURL, '').replace(global.config.devURL, '').replace('/my-account', '');
						if (path.substr(-1) === '/' && path.length > 1) { path = path.substr(0, path.length - 1); }
						if (path.includes('customer-logout')) { 
							return <Link to="/login" onClick={logout}>Logout</Link>
						}
						return <NavLink exact={true} activeClassName="active" to={"/my-account"+path}>{domNode.children[0].data}</NavLink>;
					}
				}
			})}
			{(loading || !navigation) && <ul className="woocommerce-MyAccount-navigation">
				{Array(7).fill().map((item, index) => (
					<li key={index} className="woocommerce-MyAccount-navigation-link">{isNavigation ? <Skeleton inline={true} height={16} width={140}>&nbsp;</Skeleton> : <Skeleton inline={true} height={46} width={168} />}</li>
				))}
			</ul>}
		</div>
	)
}

import React, { useState, useEffect, createContext, useContext } from 'react';

export const CartContext = createContext({
	cart: [],
	add: () => {},
	remove: () => {}
});

function getInitialState() {
	const init = localStorage.getItem('cart')
	return init ? JSON.parse(init) : []
}

export default function CartProvider({children}) {

	const [products, setProducts] = useState(getInitialState());
	
	const getProductById = (id) => {
    	return products.find(p => p.id === id)
  	}
  	const remove = (product) => {
  	  	const newProducts = products.filter(p => p.id !== product.id)
    	setProducts(newProducts)
  	}
  	const add = (product) => {
  		const existingProduct = getProductById(product.id)
		let newState = []
		if (existingProduct) {
			newState = products.map((p) => {
				if (p.id === existingProduct.id) {
					p.quantity = parseInt(p.quantity)+1;
					return p
	        	}
	        	return p        
	        })
        	setProducts(newState)
		} else {
      		product.quantity = 1;
      		setProducts([...products, product])
      	}
  	}
	
	useEffect(() => { localStorage.setItem('cart', JSON.stringify( products ) ); }, [products])

	const contextValue = {
		cart: products, add, remove
	}

	return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}

export function useCart() {
	const context = useContext(CartContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}
import React, { useState, useEffect, createContext, useContext } from 'react';
import WPApiFetch from '../Utility/WPApi'

const NavigationPlaceholder = {
	'logged_in_menu': {
		menu: {
			items: [
				{title:'How It Works',url:'',classes:''},
				{title:'About',url:'',classes:''},
			]
		}
	},
	'logged_out_menu': {
		menu: {
			items: [
				{title:'How It Works',url:'',classes:''},
				{title:'About',url:'',classes:''},
			]
		}
	},
	'logged_out_mobile': {
		menu: {
			items: [
				{title:'How It Works',url:'',classes:''},
				{title:'About',url:'',classes:''},
			]
		}
	},
	'logged_in_mobile': {
		menu: {
			items: [
				{title:'How It Works',url:'',classes:''},
				{title:'About',url:'',classes:''},
			]
		}
	},
	'footer_menu': {
		menu: {
			items: [
				{title:'About',url:'',classes:''},
				{title:'Contact Us',url:'',classes:''},
				{title:'Privacy Policy',url:'',classes:''},

			]
		}
	}
}

export const NavigationContext = React.createContext();

export default function NavigationProvider({children}) {
	const [navigation, setNavigation] = useState(NavigationPlaceholder);
	useEffect(() => {

	    async function fetchNavigation() {
		   	const route = global.config.restBase+'menu-data'
			const data = await WPApiFetch(route, this)
			setNavigation(data);
	    }
	    fetchNavigation();
	}, [])
	return <NavigationContext.Provider value={navigation}>{children}</NavigationContext.Provider>
}

export function useNavigation() {
	const context = useContext(NavigationContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}
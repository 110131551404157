import React, { useState, useEffect, createContext, useContext } from 'react';
import { getBaseURL } from '../Utility/getBaseURL'

export const SignupContext = createContext()

export default function SignupContextProvider({children}) {
	const [agree, setAgree] = useState( localStorage.getItem('agree') );
	const [newsletter, setNewsletter] = useState( localStorage.getItem('newsletter') );
	const [email, setEmail] = useState( localStorage.getItem('email') );
	const [subscription, setSubscription] = useState( parseInt( localStorage.getItem('subscription') ) );	
	const value = {email, setEmail, agree, setAgree, subscription, setSubscription, newsletter, setNewsletter};

	useEffect(() => {
		email && localStorage.setItem('email', email );
	}, [email]);	
	useEffect(() => {
		subscription && localStorage.setItem('subscription', parseInt( subscription ) );
	}, [subscription]);
	useEffect(() => {
		agree && localStorage.setItem('agree', agree);
	}, [agree]);
	useEffect(() => {
		if( subscription && ( subscription === global.config.freeProductID ) && newsletter != 'accept' ) { 
			localStorage.removeItem('newsletter'); 
			setNewsletter(false);
		}
		else { newsletter && localStorage.setItem('newsletter', newsletter ); }
	}, [newsletter, subscription]);

	return <SignupContext.Provider value={value}>{children}</SignupContext.Provider>
}

export function useSignup() {
	const context = useContext(SignupContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}
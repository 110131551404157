import React from 'react'
import SiteBranding from '../Common/SiteBranding'
import "./ErrorBoundary.scss"

const ErrorBoundaryFallback = ({error, resetErrorBoundary}) => {	
	switch( error ) {
		case 'Token has been revoked.': 
		case 'Expired token': 
		case 'Cookie nonce is invalid':
		case 'Cookie check failed':
			//setTimeout(function() { window.location = '/login' }, 1000);
			return (
				<div id="App" className="App container-fluid">
					<main className="App-content">
						<div className="container error">
							<SiteBranding />
							<h2>You have been logged out.</h2>
							<p>Redirecting to Login Screen</p>
						</div>
					</main>
				</div>
			);
		default: 
			return (
				<div id="App" className="App container-fluid">
					<main className="App-content">
						<div className="container error">
							<SiteBranding />
							<h2>Oops! Something went wrong.</h2>
							<p><a className="btn btn-primary" href="/">Click Here To Return Home</a></p>
						</div>
					</main>
				</div>
			);
	}
}
export default ErrorBoundaryFallback
import 'intersection-observer';

import 'core-js/es/object';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/weak-map';
import 'core-js/es/weak-set';
import "core-js/es/array/flat-map";
import 'proxy-polyfill';


import React from 'react';
import ReactDOM from 'react-dom/client';
//import Preloader from './components/Utility/Preloader';
import App from './App';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ThemeOptionsProvider from './components/Contexts/ThemeOptionsContext';
//import { ServiceWorkerProvider } from './components/Contexts/ServiceWorkerContext';

import LoginProvider from './components/Auth/LoginContext';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryFallback from './components/Utility/ErrorBoundary';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import ScrollToTop from './components/Utility/ScrollToTop'
import './config';
import './scss/index.scss';

//import reportWebVitals from './reportWebVitals';


const advancedMatching = {};
const options = {
  autoConfig: true,
  debug: false,
};

if ( !(process.env.NODE_ENV && process.env.NODE_ENV === 'development') ) {
	ReactGA.initialize('UA-220152633-1');
	ReactPixel.init(803796053924609, advancedMatching, options);

}
const updateTracking = () => {
	if ( !(process.env.NODE_ENV && process.env.NODE_ENV === 'development') ) {
		ReactGA.pageview(window.location.pathname);
		ReactPixel.pageView();
	}
	return null;
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
		{/*<Preloader/>*/}
		{/*<ServiceWorkerProvider>*/}
			<LoginProvider>
				<ThemeOptionsProvider>
					<Router>
						<ScrollToTop/>
						<Route path="/" component={updateTracking} />
						<App />
					</Router>
				</ThemeOptionsProvider>
			</LoginProvider>
		{/*</ServiceWorkerProvider>*/}
	</ErrorBoundary>);
//import React, { useContext } from 'react';
import { replaceURL } from "./replaceURL";
//import { isPreRendering } from './isPreRendering'
import { getBaseURL } from './getBaseURL'
//import { useLogin } from '../Auth/LoginContext'


//import { public_routes, logged_in_routes, subscription_routes } from './cachedRoutes'
//import { checkLocalStorage, checkSessionStorage } from './debug.js'

//localStorage.clear();
//sessionStorage.clear();

/*
export const useWPApiFetch = async(route, target=null, method="GET", body=null, credentials="omit") => {
	const { testlogin, setTestlogin, testuser } = useLogin();
	console.log(testlogin);
	return await WPApiFetch(route, target, method, body, credentials);
}	

export function wpFetch(route, nonce=null, body=null, method="GET", credentials="omit", target=null) {
	
}
*/

export default function WPApiFetch(route, target=null, method="GET", body=null, credentials="omit", nonce=null) {
	const baseURL = getBaseURL();
	let Headers = {}	
	if(nonce) {
		Headers['X-WP-Nonce'] = nonce;
		credentials = 'include';
	}
	const restUrl = baseURL+'/wp-json'+route
	const Method = method
	let devURL = '';
	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
		const delimiter = (restUrl.includes('?')) ? "&" : "?";
		devURL = delimiter+'dev=true';
	}
	/*
	const storagedata = JSON.parse( sessionStorage.getItem(route) );
	if(storagedata !== null) {
		if(global.public_routes.includes(route)) {
			console.log('From Storage: '+route);
			return Promise.resolve(storagedata);
		}
		if(login && global.logged_in_routes.includes(route)) {
			console.log('From Logged In Storage: '+route);
			return Promise.resolve(storagedata);
		}
		if(login && login.active && global.subscription_routes.includes(route)) {
			console.log('From Subscription Storage: '+route);
			return Promise.resolve(storagedata);
		}
	}
	*/

	//console.log(credentials);

	return fetch(restUrl+devURL, { method:Method, credentials:credentials, headers:Headers, body:body } )
		.then(async response => {
			/*
			if(response.redirected === true) {
				const url = response.url.replace(baseURL, '');
				if(target) { target.props.history.push(url); }
				return Promise.reject(null);
			}
			*/
			const data = await response.json();
			if (!response.ok) {
				switch(response.status) {
					case 401: 
						break;
					case 403: 
						break;
					default: 
						break;
				}

				const error = (data && data.message) || response.statusText;
				return Promise.reject(error);
			}
			if(data.redirect) {
				const url = replaceURL(data.redirect);
				if(target) { target.props.history.push(url); }
				return Promise.reject(null);
			}
			if(data.reload) {
				const url = replaceURL(data.reload);
				window.location.href = url;
				return Promise.reject(null);
			}
			data['total'] = response.headers.get('x-wp-total');
			data['totalpages'] = response.headers.get('x-wp-totalpages');
			data['nonce'] = response.headers.get('x-wp-nonce');

			//sessionStorage.setItem(route, JSON.stringify(data));
			return Promise.resolve(data);
	  	})
		.catch( error => {
			if(target) {
				target.setState({error: error});
			} else {
				throw(error);
			}
			return Promise.reject(error);
		});
}
import React from "react"
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaypal, faCcVisa, faCcMastercard } from '@fortawesome/free-brands-svg-icons'
import { replaceURL } from '../Utility/replaceURL';
import { useNavigation } from '../Contexts/NavigationContext';
import { useLogin } from '../Auth/LoginContext';
import { ReactComponent as SSLBadge } from '../../assets/ssl.svg';
import SocialMediaLinks from "../Footer/SocialMediaLinks"
import SiteBranding from '../Common/SiteBranding'

import "./Footer.scss"

const DevelopmentFunctions = () => {
	const { login, logout, user } = useLogin();
	return (
	<div className="development-functions">
		{login && <span>Logged in as: {user && user.display_name}<br/><button className="btn btn-link p-0" onClick={logout}>Logout</button></span> }
	</div>
	)
}

const PaymentIcons = () => (
	<React.Fragment>
		<FontAwesomeIcon icon={faPaypal} /> 
		<FontAwesomeIcon icon={faCcVisa} /> 
		<FontAwesomeIcon icon={faCcMastercard} />
	</React.Fragment>
)

const NavMenu = ({ items, navClass }) => (
	<nav className={navClass && navClass}>
		<ul className="navigation">
			{items && items.map((item,inx) => (
				<li key={inx}><NavLink activeClassName="active" className={item.classes} to={{ pathname: replaceURL(item.url) }}>{item.title}</NavLink></li>
			))}
		</ul>
	</nav>
)

export default function Footer() {
	const navigation = useNavigation();
	return (
		<div className="container mb-2">
			<div className="row footer-row-1 footer-links">
				<div className="col-12 col-sm-12 col-md-3 footer-left">
					<SiteBranding link={true} />
				</div>
				<NavMenu items={navigation && navigation.footer_menu.menu.items} navClass={"col-12 col-sm-12 col-md-7 footer-menu"} />
				<div className="col-12 col-sm-12 col-md-2 footer-right">
					<SocialMediaLinks/>
				</div>
			</div>
			<div className="row footer-row-2">
				<div className="col-12 col-sm-12 col-md-3 payment-icons">
					<PaymentIcons/>
				</div>
				<div className="col-12 col-sm-12 col-md-6 copyline">
					<span>&copy; {new Date().getFullYear() + " " + global.config.siteName}</span>
					<span>|</span>
					<span><SSLBadge/> Secured with SSL</span>
				</div>
				<div className="col-12 col-sm-12 col-md-3">
					<DevelopmentFunctions/>
				</div>
			</div>
		</div>
	)
}
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { useThemeOptions } from "../Contexts/ThemeOptionsContext"

const iconMap = {
	facebook: faFacebookF,
	twitter: faTwitter,
	instagram: faInstagram,
}

const SocialMediaLinks = () => {
	const options = useThemeOptions();
	return (
		<div className="social-media-links">
			{options && Object.entries(options.acf.social_media_links).map(([index, item]) => {
				if( item ) return <a key={index} href={item}><FontAwesomeIcon icon={iconMap[index]} /></a>
				return null;
			})}
		</div>
	)
}

export default SocialMediaLinks
import React, { useState, useEffect, createContext, useContext } from 'react';
import { getBaseURL } from '../Utility/getBaseURL'

export const LoginContext = createContext({
	testlogin: null,
	nonce: null,
	setLogin: () => {}
});

export default function LoginContextProvider({children}) {
	const [login, setLogin] = useState(false);
	const [user, setUser] = useState(null);
	const logout = () => {
		const formData = new FormData();
		formData.append('action','ajaxlogout');
		fetch(getBaseURL() + '/wp-admin/admin-ajax.php', {
			method: "post",
			credentials: "include",
			headers: {},
			body: formData,
		}).then(async response => {
			await response.json();
			setLogin(false);
			setUser(null);
			localStorage.clear();
            document.documentElement.classList.remove('mobile-nav-open');
		});
	}

	useEffect(() => {
	    async function fetchLogin() {
			const formData = new FormData();
			formData.append('action','checklogin');
			fetch(getBaseURL() + '/wp-admin/admin-ajax.php', {
				method: "post",
				credentials: "include",
				headers: {},
				body: formData,
			} )
			.then(async response => {
				const data = await response.json();
				setUser(data);
				setLogin(data.loggedin);
	            document.documentElement.classList.remove('mobile-nav-open');

			}).catch(e => {
				throw(e);
			});
	    }
	    fetchLogin();
	}, [login])
	
	//console.log(user);
	//console.log(login);
	
	const value = { login, setLogin, logout, user };
	return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
}

export function useLogin() {
	const context = useContext(LoginContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}
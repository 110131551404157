import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Icon } from "@iconify/react";
import chevronDown from "@iconify/icons-mdi/chevron-down";
import SiteBranding from '../Common/SiteBranding'
//import SearchForm from '../Common/SearchForm'
import AccountNavigation from '../Woocommerce/AccountNavigation'
import { MobileNavigation } from './MobileNavigation'
import { ReactComponent as AccountIcon} from '../../assets/carbon_user.svg';
import { replaceURL} from '../Utility/replaceURL';
import { useNavigation } from '../Contexts/NavigationContext';
import { useThemeOptions } from '../Contexts/ThemeOptionsContext';

import { useLogin } from "../Auth/LoginContext"

import "./Navigation.scss"

const positionMenus = () => {
	const elements = document.querySelectorAll('.megamenu');
	const container = document.querySelector('.site-navigation').getBoundingClientRect();
	elements.forEach((el,inx) => {
		let div = el.querySelector('div');
		if(div) {
			let viewportOffset = div.getBoundingClientRect();
			if(viewportOffset.right > ( container.width - 16 ) ) {
				div.style.left = -( viewportOffset.left + viewportOffset.width - container.width + 16 ) + "px";
			}
		}
	});
}
const RenderImage = ({src}) => {
	if(src) { return <img src={src} alt="" /> } 	
	return <React.Fragment/>
}
const RenderMenuItems = props => (
	<React.Fragment>
	{props.items.map((item, index) => (
		<li onMouseEnter={()=>{ positionMenus();}} key={index} className={ ( (item.children) ? 'has_children ' : '' ) + item.classes } >
			<RenderLink {...props} item={item} />
			<RenderChildren {...props} item={item} />
		</li>
	))}
	</React.Fragment>
)

export const RenderLink = ({item}) => {
	let activeClassName = (item.search) ? "" : "active"
	let media_src = item.image 

	if( replaceURL(item.url) === '/' ) {
		return <NavLink exact 
			activeClassName={activeClassName}
			to={{ 
				pathname: replaceURL(item.url).replace("?"+item.search) , 
				state: { pageID: item.id },
				search: item.search,
			}}><RenderImage src={media_src} />{item.title}</NavLink>;
	}
	else {
		return <NavLink 
			activeClassName={activeClassName}
			to={{ 
				pathname: replaceURL(item.url), 
				state: { pageID: item.id }, 
				search: item.search,
			}}
			onClick={function(ev) { 
				const childElement = ev.target;
				const parentElement = childElement.closest(".has_children");
				if(parentElement) {
					parentElement.style.overflow = 'hidden';
					setTimeout(function() { parentElement.style.overflow = 'initial'; }, 200)
				}
			}}
			><RenderImage src={media_src} />{item.title}</NavLink>;
	}
}

const RenderChildren = props => {
	if(props.item.children) {
		if(props.item.object_slug === 'videos') {
			return <RenderVideoMegaMenu {...props} /> 
		}
		const childLinks = props.item.children.map(child => {
			return <li className={child.classes} key={child.id}><RenderLink {...props} item={child} /></li>;
		});
		return <React.Fragment><Icon className="rotate" icon={chevronDown} /><div><ul>{childLinks}</ul></div></React.Fragment>;
	}
	return null;
}

const RenderVideoMegaMenu = props => {
	const genders = props.item.gender_list.map(child => {
		return <li key={child.id}><RenderLink {...props} item={child} /></li>;
	});
	const relationship_statuses = props.item.relationship_status_list.map(child => {
		return <li key={child.id}><RenderLink {...props} item={child} /></li>;
	});
	const categories = props.item.categories_list.map(child => {
		return <li key={child.id}><RenderLink {...props} item={child} /></li>;
	});
	return (
		<React.Fragment>
			<Icon className="rotate" icon={chevronDown} /> 
			<div>
			<ul className="list-unstyled">
				<li>
					<ul className="relationship_status">
						<li className="menu-header">Relationship Status</li>
						{relationship_statuses}
					</ul>
				</li>
				<li>
					<ul className="gender">
						<li className="menu-header">Gender</li>
						{genders}
					</ul>
				</li>
				<li>
					<ul className="latest_videos">
						<li className="menu-header">Categories</li>
						<li className="menu-header menu-header-right view-all"><Link to="/videos">View All</Link></li>
						{categories}
					</ul>
				</li>
			</ul>
			</div>
		</React.Fragment>
	)
}

export default function Navigation(props) {
	const { login, setLogin, user } = useLogin();
	const navigation = useNavigation();
	const options = useThemeOptions();
	let targetNavigation = null;
	if(navigation && login) targetNavigation = navigation.logged_in_menu; 
	if(navigation && !login && ( !user || ( user && !user.subscription ) ) ) targetNavigation = navigation.logged_out_menu; 
	return (
		<div className={"site-navigation "+( login && "logged-in" )+" "+(login && user.subscription && "active" )}>
			<SiteBranding link={true} />
			{targetNavigation && login && <nav className="nav logged-in">
				<ul className="navigation list-unstyled">
					<RenderMenuItems {...props} items={targetNavigation.menu.items} />
				</ul>
			</nav>}
			{targetNavigation && !login && <nav className="nav">
				<ul className="navigation list-unstyled">
					<RenderMenuItems {...props} items={targetNavigation.menu.items} />
				</ul>
			</nav>}
			<nav className="nav top-right">
				<ul className="navigation list-unstyled">
					{login && <li className="has_children my_account">
						<NavLink className="my-account" to="/my-account"><span className="my-account-icon"><AccountIcon /></span> My Account</NavLink>
						<AccountNavigation onMouseEnter={()=>{console.log('enter')}} {...props} isNavigation={true} />
					</li>}
					{!login && <li><NavLink className={"btn my-account " + ((options && options.acf && options.acf.color_configuration) ? "btn-"+options.acf.color_configuration.account_button_style : "btn-primary") } to="/signup">Get Started</NavLink></li>}
				</ul>
			</nav>
			<MobileNavigation setLogin={setLogin} items={(login && user.subscription) ? navigation.logged_in_mobile.menu.items : navigation.logged_out_mobile.menu.items} />
		</div>
	)
}
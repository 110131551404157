import React from 'react';
import { isPreRendering } from './isPreRendering'

export const InvalidateLocalStorage = () => {
	if(isPreRendering()) return <div/>

	console.log('clearing storage');

	localStorage.clear();
	sessionStorage.clear();

	window.location.href = '/';
	return <div/>;
}
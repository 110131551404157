import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from "react-router-dom";
import { InvalidateLocalStorage } from './components/Utility/InvalidateLocalStorage';
import { useLogin } from "./components/Auth/LoginContext"
//import { useServiceWorker } from './components/Contexts/ServiceWorkerContext';
import { useThemeOptions } from './components/Contexts/ThemeOptionsContext';
import { SkeletonTheme } from "react-loading-skeleton";
import { AnimatePresence } from "framer-motion"
import { MountTransition } from './components/Utility/AnimatedRoutes';

//import variables from './scss/variables.scss';
import "./scss/main.scss";
import "react-multi-carousel/lib/styles.css";

import NavigationProvider from './components/Contexts/NavigationContext';
import SignupContextProvider from './components/Auth/SignUpContext';
import CartProvider from './components/Woocommerce/Contexts/CartContext';

import Navigation from './components/Header/Navigation';
import Footer from './components/Footer/Footer';

const SignUp = React.lazy(() => import('./components/Auth/SignUp'));
const LoginTest = React.lazy(() => import('./components/Auth/Login'));
const Maintenance = React.lazy(() => import('./components/Maintenance'));
const Nothing = React.lazy(() => import('./components/Nothing'));
const Home = React.lazy(() => import('./components/Home'));
const HomeLoggedIn = React.lazy(() => import('./components/HomeLoggedIn'));
const Landing = React.lazy(() => import('./components/Landing'));
const Facilitators = React.lazy(() => import('./components/Content/Facilitators'));
const Facilitator = React.lazy(() => import('./components/Content/Facilitator'));
const WPPage = React.lazy(() => import('./components/Content/WPPage'));
const Account = React.lazy(() => import('./components/Woocommerce/Account'));
const OrderReceived = React.lazy(() => import('./components/Auth/SignUp/OrderReceived'));
const Checkout = React.lazy(() => import('./components/Auth/SignUp/CheckoutForm'));
const Forbidden = React.lazy(() => import('./components/Auth/Forbidden'));
const Blog = React.lazy(() => import('./components/Content/Blog'));
const Livestream = React.lazy(() => import('./components/Content/Livestream'));

const Product = React.lazy(() => import('./components/Woocommerce/Product'));
const Products = React.lazy(() => import('./components/Woocommerce/Products'));
const Cart = React.lazy(() => import('./components/Woocommerce/Cart'));

const VimeoArchive = React.lazy(() => import('./components/Vimeo/Archive'));
const VimeoSingle = React.lazy(() => import('./components/Vimeo/Single'));
const VimeoPlaylist = React.lazy(() => import('./components/Vimeo/Playlist'));
const VimeoPlaylistArchive = React.lazy(() => import('./components/Vimeo/PlaylistArchive'));

const Captivate = React.lazy(() => import('./components/CaptivateFM/Single'));


//const Categories = React.lazy(() => import('./components/Categories'));
//const Category = React.lazy(() => import('./components/Category'));
//const WCCart = React.lazy(() => import('./components/WCCart'));
//const WCCheckout = React.lazy(() => import('./components/WCCheckout'));
//const WCOrderReceived = React.lazy(() => import('./components/WCOrderReceived'));

function App() {
	const options = useThemeOptions();
	const { login, user } = useLogin();
	const location = useLocation();

	let locationKey = location.pathname;
	
	if(location.pathname.includes('/my-account')) {
		locationKey = '/my-account'
	}
	if(location.pathname.includes('/signup')) {
		locationKey = '/signup'
	}
	//const { isUpdateAvailable, updateAssets } = useServiceWorker();
	useEffect(()=> {
		/*
		navigator.serviceWorker.getRegistrations()
			.then(registrationsArray => {
				registrationsArray[0] && registrationsArray[0].update();
			})
		*/
		switch(locationKey) {
			case "/landing": 
				//document.body.classList.add('landing');
				break;
			default: 
				//document.body.classList.remove('landing');
				break;
		}

	}, [locationKey]);

   	function maintenance_check() {
    	let check = false; 	
    	if(options && options.acf.general_settings.maintenance) {
    		check = true; 
    	}
		if(user && user.roles && user.roles.includes('administrator')) {
			check = false;
		}
		return check;
	}
	//console.log(isUpdateAvailable);
    return (
        <SkeletonTheme baseColor={'#444444'} highlightColor={'#666666'}>
			<SignupContextProvider>
	        <CartProvider>
	        <NavigationProvider>
		        <div id="App" className="App container-fluid">
			            { !maintenance_check() && options && <header className="App-header"><Navigation /></header> }
						<main className="App-content">
							{/*isUpdateAvailable && <button className="service-notice" onClick={updateAssets}>An updated version of relationflix is available! Click here to update now.</button>*/}
							{!navigator.onLine && <div className="service-notice">No Internet Connection</div>}
							<AnimatePresence mode="wait" initial={true}>
								<MountTransition key={locationKey} className="transition-container">
									<React.Suspense fallback={<div/>}>
									
									{ options && <Switch location={location} >
										{ maintenance_check() && <Maintenance/> }
										{ (login && user.subscription) && <Route exact path="/" component={HomeLoggedIn} /> }
										<Route path="/my-account/lost-password" component={LoginTest} />
										<Route path="/login" component={LoginTest}/> 
										<Route path="/signup" render={(props) => <SignUp {...props} /> } />
										{/* (login && user && user.active) && <Route exact path="/login"><Redirect from='/login' to='/' /></Route> }
										{ (login && user && !user.active) && <Route exact path="/login"><Redirect from='/login' to='/signup' /></Route> */}


										{/*
										<Route exact path="/videos" component={Videos}/>
										<Route exact path="/video/:mediaID" component={Video}/>
										<Route exact path="/courses" component={Playlists}/>
										<Route exact path="/courses/:playlistID" component={Playlist}/>
										<Route exact path="/courses/:playlistID/video/:mediaID" component={Video}/>
										*/}

										<Route exact path="/videos" component={VimeoArchive}/>
										<Route exact path="/video/:mediaID" component={VimeoSingle}/>
										<Route exact path="/courses" component={VimeoPlaylistArchive}/>
										<Route exact path="/courses/:playlistSlug" component={VimeoPlaylist}/>
										<Route exact path="/courses/:playlistSlug/video/:mediaID" component={VimeoSingle}/>

										<Route exact path="/vimeo" component={VimeoArchive}/>
										<Route exact path="/vimeo/:mediaID" component={VimeoSingle}/>
										<Route exact path="/vimeo_playlists" component={VimeoPlaylistArchive}/>
										<Route exact path="/vimeo_playlist/:playlistSlug" component={VimeoPlaylist}/>
										<Route exact path="/vimeo_playlist/:playlistSlug/video/:mediaID" component={VimeoSingle}/>

										{/*<Route exact path="/facilitators" component={login ? Facilitators : Forbidden}/>
										<Route exact path="/facilitator/:facilitator" component={login ? Facilitator : Forbidden}/>
										<Route exact path="/podcasts" component={login ? Podcasts : Forbidden}/>
										<Route exact path="/podcasts/:podcast" component={login ? Podcasts : Forbidden}/>*/}

										<Route exact path="/captivate-podcast/:podcast" component={login ? Captivate : Forbidden}/>

										<Route exact path="/educators" component={Facilitators}/>
										<Route exact path="/educator/:facilitator" component={Facilitator}/>

										<Route exact path="/explore" component={HomeLoggedIn}/>
										<Route exact path="/landing" component={Landing}/>

										<Route exact path="/blog" component={Blog}/>
										<Route exact path="/blog/:post" component={Blog}/>

										<Route exact path="/livestream" component={(login && user.subscription) ? Livestream : Forbidden}/>

										{ !login && <Route path="/my-account" component={LoginTest} /> }
										{ !login && <Route exact path="/complete-your-order" component={LoginTest} /> }
										{ !login && <Route exact path="/complete-your-order/order-pay/:wc_order" component={LoginTest} /> } /> }
										{ login && <Route exact path="/my-account" render={(props) => <Account {...props} /> } /> }
										{ login && <Route path="/my-account/:wc_endpoint/:wc_object/:wc_action/:wc_page" render={(props) => <Account {...props} /> } /> }
										{ login && <Route path="/my-account/:wc_endpoint/:wc_object/:wc_action" render={(props) => <Account {...props} /> } /> }
										{ login && <Route path="/my-account/:wc_endpoint/:wc_action" render={(props) => <Account {...props} /> } /> }
										{ login && <Route path="/my-account/:wc_endpoint" render={(props) => <Account {...props} /> } /> }
										{ login && <Route path="/delete-payment-method/:wc_var" render={(props) => <Account {...props} /> } /> }
										{ login && <Route path="/set-default-payment-method/:wc_var" render={(props) => <Account {...props} /> } /> }
										{ login && <Route exact path="/complete-your-order/order-received/:wc_order" component={OrderReceived} /> } /> }
										{ login && <Route exact path="/complete-your-order/order-pay/:wc_order" render={(props) => <Checkout /> } /> }
										{ login && <Route exact path="/complete-your-order" render={(props) => <Checkout /> } /> }

										<Route path="/cart/:action/:wc_var" render={(props) => <Cart {...props} /> } />
										<Route path="/cart/:wc_var" render={(props) => <Cart {...props} /> } />
										<Route exact path="/cart" render={(props) => <Cart {...props} /> } />

										{ /*login && <Route exact path="/my-account" render={(props) => <Account {...props} setLogin={setLogin} /> } /> }
										{ login && <Route path="/my-account/:wc_endpoint/:wc_action" render={(props) => <Account {...props} setLogin={setLogin} /> } /> }
										{ login && <Route path="/my-account/:wc_endpoint" render={(props) => <Account {...props} setLogin={setLogin} /> } /> }
										{ login && <Route path="/delete-payment-method/:wc_var" render={(props) => <Account {...props} setLogin={setLogin} /> } /> }
										{ login && <Route path="/set-default-payment-method/:wc_var" render={(props) => <Account {...props} setLogin={setLogin} /> } /> */}
										
										<Route path="/products" component={Products} />
										<Route path="/product/:product_slug" component={Product} />
										
										<Route exact path="/invalidate" component={InvalidateLocalStorage} />
										<Route path="/:page_slug/:subpage_slug" component={WPPage} />
										<Route path="/:page_slug" component={WPPage} />
										<Route exact path="/" component={Home} />
										<Route component={Nothing} />
									</Switch> }
									</React.Suspense>
								</MountTransition>
							</AnimatePresence>
						</main>
			        	{ !maintenance_check() && options && <footer className="App-footer"><Footer /></footer> }
		        </div>
	        </NavigationProvider>
	        </CartProvider>
			</SignupContextProvider>
		</SkeletonTheme> 
    );
}
export default App;
module.exports = global.config = {
    siteURL: 'https://relationflix.infinitelabyrinth.com',
	buildURL: 'https://relationflix.infinitelabyrinth.com',
	devURL: 'https://node.infinitelabyrinth.com',
    siteName: 'Relationflix',
    rfRestRoute: '/wp-json/r-flix/v1/',
    wpRestRoute: '/wp-json/wp/v2/',
    jwtRestRoute: '/wp-json/simple-jwt-authentication/v1/',
    /*facebookAppID: '981659965781919',*/
    facebookAppID: '2049531482072403',
    googleAppID: '1050502518849-6g87qr5pjr6imqv7rtret7v1lmq3o2iu.apps.googleusercontent.com',
    restBase: '/r-flix/v1/',
    videoSlug: 'vimeo',
    playlistSlug: 'vimeo_playlist',
    filtersSlug: 'vimeo_filters',
    freeProductID: 9032,
};

module.exports = global.public_routes = [
    '/rflix/v1/menu-data',
    '/rflix/v1/filters',
    '/rflix/v1/frontpage',
    '/wp/v2/pages?slug=about',
    '/wp/v2/pages?slug=how-it-works',
    '/wp/v2/pages?slug=privacy-policy',
    '/wp/v2/pages?slug=contact-us',
    '/wp/v2/pages/6207',
];
module.exports = global.logged_in_routes = [
    '/rflix/v1/wc_account_navigation',
];
module.exports = global.subscription_routes = [
    '/wp/v2/podcasts?per_page=100',
    '/wp/v2/playlists?per_page=12',
    '/wp/v2/videos?per_page=12',
];


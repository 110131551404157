import React from "react"
import { Link } from "react-router-dom"
import {ReactComponent as SiteLogo} from '../../assets/logo.svg';
import logo from '../../assets/Relationflix-Logo-Aug2023.png';
import './SiteBranding.scss';

export const SiteBranding = ({link}) => {
	return (
		<React.Fragment>
		{link && <Link className="site-branding" to="/">{/*<SiteLogo/>*/}<img src={logo} /></Link>}
		{!link && <span className="site-branding" to="/">{/*<SiteLogo/>*/}<img src={logo} /></span>}
		</React.Fragment>
	)
};

export default SiteBranding
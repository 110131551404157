import React from 'react'
import { motion } from 'framer-motion'

const variants = {
	initial: {
		opacity: 0,
	},
	in: {
		opacity: 1,
	},
	out: {
		opacity: 0,
	},
	transition: {
		delayChildren: 0.3,
		staggerChildren: 0.2
	}
}

export const MountTransition = ({className, children}) => (
	<motion.div initial="initial" animate="in" exit="out" transition={{ duration: 0.15 }} variants={variants} className={className}>
		{children}
	</motion.div>
)